import jwtDecode from "jwt-decode";
import axios from "axios";

import config from "../../config";

export type Method =
  | "get"
  | "GET"
  | "delete"
  | "DELETE"
  | "head"
  | "HEAD"
  | "options"
  | "OPTIONS"
  | "post"
  | "POST"
  | "put"
  | "PUT"
  | "patch"
  | "PATCH"
  | "purge"
  | "PURGE"
  | "link"
  | "LINK"
  | "unlink"
  | "UNLINK";

interface Config {
  method: Method;
  url: string;
  data?: any;
  headers?: any;
}

type Response = {
  status: number;
  config: any;
  data?: any;
  headers: any;
  request: any;
  statusText?: string;
};

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
//axios.defaults.baseURL = config.API_URL;

// intercepting to capture errors
axios.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    console.log(error);

    let message;

    if (error && error.response && error.response.status === 404) {
      // window.location.href = '/not-found';
    } else if (error && error.response && error.response.status === 403) {
      window.location.href = "/access-denied";
    } else {
      console.log(error?.response);
      switch (error?.response?.status) {
        case 401:
          message = "Invalid credentials";
          localStorage.removeItem("AUTH_TOKEN");
          return error;
          //window.location.href = '/auth/login'
          break;
        case 403:
          message = "Access Forbidden";
          break;
        case 404:
          message = "Sorry! the data you are looking for could not be found";
          break;
        default: {
          message =
            error.response && error.response.data
              ? error.response.data["message"]
              : error.message || error;
        }
      }
      return Promise.reject(message);
    }
  }
);

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string | null) => {
  console.log("token =>", token);
  if (token) axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

const getToken = () => {
  const token = localStorage.getItem("AUTH_TOKEN");
  return token;
};
class APICore {
  /**
   * Fetches data from given url
   */
  get = (url: string, params: any) => {
    let response;

    if (url === "https://api.kisi.io/locks") {
      const options: Config = {
        method: "GET",
        url: "https://api.kisi.io/locks?limit=250",
        headers: {
          "Content-Type": "application/json",
          Authorization: "KISI-LOGIN 1a91144f7a387c1009de22561372b8d6",
          Accept: "application/json; charset=utf-8",
        },
        data: {},
      };
      response = axios.request(options);
    } else {
      setAuthorization(getToken());
      if (params) {
        var queryString = params
          ? Object.keys(params)
              .map((key) => key + "=" + params[key])
              .join("&")
          : "";
        response = axios.get(`${url}?${queryString}`, params);
      } else {
        response = axios.get(`${url}`, params);
      }
    }

    return response;
  };

  getFile = (url: string, params: any) => {
    let response;
    setAuthorization(getToken());
    if (params) {
      var queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
      response = axios.get(`${url}?${queryString}`, { responseType: "blob" });
    } else {
      response = axios.get(`${url}`, { responseType: "blob" });
    }
    return response;
  };

  getMultiple = (urls: string, params: any) => {
    setAuthorization(getToken());
    const reqs = [];
    let queryString = "";
    if (params) {
      queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
    }

    for (const url of urls) {
      reqs.push(axios.get(`${url}?${queryString}`));
    }
    return axios.all(reqs);
  };

  /**
   * post given data to url
   */
  create = async (url: string, data: any) => {
    console.log({ url, data });

    //setAuthorization(getToken())
    let config: Config = {
      method: "POST",
      url: url,
      data,
    };

    console.log(config);

    let result = await axios(config);

    return result;
    // console.log({ url, data });
    // console.log(axios);

    // return await axios.post(url, data);
  };

  /**
   * Updates patch data
   */
  updatePatch = (url: string, data: any) => {
    setAuthorization(getToken());
    return axios.patch(url, data);
  };

  /**
   * Updates data
   */
  update = (url: string, data: any) => {
    setAuthorization(getToken());
    return axios.put(url, data);
  };

  /**
   * Deletes data
   */
  delete = (url: string) => {
    setAuthorization(getToken());
    return axios.delete(url);
  };

  /**
   * post given data to url with file
   */
  createWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return axios.post(url, formData, config);
  };

  /**
   * post given data to url with file
   */
  updateWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return axios.patch(url, formData, config);
  };

  isUserAuthenticated = () => {
    const user = getToken();
    console.log(user);

    if (!user) {
      return false;
    } else {
      return true;
    }
  };
}

/*
Check if token available in session
*/

export { APICore, setAuthorization };
