import React from "react";
import { Route, RouteProps } from "react-router-dom";
import OtherDetails from "../pages/user/OtherDetails";

// components
import PrivateRoute from "./PrivateRoute";
import Root from "./Root";

// lazy load all the views

// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
//const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
//const Register = React.lazy(() => import("../pages/auth/Register"));
const LockScreen = React.lazy(() => import("../pages/auth/LockScreen"));

// dashboard
const EcommerceDashboard = React.lazy(
  () => import("../pages/dashboard/Ecommerce/"),
);
const AnalyticsDashboard = React.lazy(
  () => import("../pages/dashboard/Analytics/"),
);

// apps
const CalendarApp = React.lazy(() => import("../pages/apps/Calendar/"));
const Profile = React.lazy(() => import("../pages/profile"));
const Notifications = React.lazy(() => import("../pages/notifications"));
const Report = React.lazy(() => import("../pages/report"));
const Community = React.lazy(() => import("../pages/community"));
const Resources = React.lazy(() => import("../pages/resources"));
const Plans = React.lazy(() => import("../pages/plans"));
const Invoices = React.lazy(() => import("../pages/invoices"));
const ViewInvoice = React.lazy(() => import("../pages/invoices/ViewInvoice"));
const User = React.lazy(() => import("../pages/user/User"));
const UserView = React.lazy(() => import("../pages/user/Profile"));
const Team = React.lazy(() => import("../pages/team/Team"));
// - chat
const ChatApp = React.lazy(() => import("../pages/apps/Chat/"));

// extra pages
const Error404 = React.lazy(() => import("../pages/error/Error404"));
const Error500 = React.lazy(() => import("../pages/error/Error500"));
// -other
const Starter = React.lazy(() => import("../pages/other/Starter"));
//const Profile = React.lazy(() => import('../pages/other/Profile'));
const Activity = React.lazy(() => import("../pages/other/Activity"));
const Invoice = React.lazy(() => import("../pages/other/Invoice"));
const Maintenance = React.lazy(() => import("../pages/other/Maintenance"));
const Pricing = React.lazy(() => import("../pages/other/Pricing"));

// uikit
const UIElements = React.lazy(() => import("../pages/uikit"));

// widgets
const Widgets = React.lazy(() => import("../pages/widgets/"));

// forms
const BasicForms = React.lazy(() => import("../pages/forms/Basic"));
const FormAdvanced = React.lazy(() => import("../pages/forms/Advanced"));
const FormValidation = React.lazy(() => import("../pages/forms/Validation"));
const FormWizard = React.lazy(() => import("../pages/forms/Wizard"));
const FileUpload = React.lazy(() => import("../pages/forms/FileUpload"));
const Editors = React.lazy(() => import("../pages/forms/Editors"));

// charts
const Charts = React.lazy(() => import("../pages/charts/"));

// tables
const BasicTables = React.lazy(() => import("../pages/tables/Basic"));
const AdvancedTables = React.lazy(() => import("../pages/tables/Advanced"));

export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  component?: RouteProps["component"];
  route?: any;
  exact?: RouteProps["exact"];
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}

// root routes
const rootRoute: RoutesProps = {
  path: "/",
  exact: true,
  component: () => <Root />,
  route: Route,
};

// dashboards
const dashboardRoutes: RoutesProps = {
  path: "/analytics",
  name: "Analytics",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/analytics",
      name: "Ecommerce",
      component: EcommerceDashboard,
      route: PrivateRoute,
    },
    {
      path: "/dashboard/analytics",
      name: "Analytics",
      component: AnalyticsDashboard,
      route: PrivateRoute,
    },
  ],
};

const calendarAppRoutes: RoutesProps = {
  path: "/apps/calendar",
  name: "Calendar",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "calendar",
  component: CalendarApp,
  header: "Apps",
};

const userRoutes: RoutesProps = {
  path: "/user",
  name: "User",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "user",
  children: [
    {
      path: "/user",
      name: "User",
      component: User,
      route: PrivateRoute,
      exact: true,
    },
    {
      path: "/user/:userId",
      name: "User View",
      component: UserView,
      route: PrivateRoute,
    },
  ],
};

const teamRoutes: RoutesProps = {
  path: "/team",
  name: "Team",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "user",
  component: Team,
};

const communityAppRoutes: RoutesProps = {
  path: "/community",
  name: "Community",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "share-2",
  component: Community,
};

const profileAppRoutes: RoutesProps = {
  path: "/profile",
  name: "Profile",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "share-2",
  component: Profile,
};

const invoicesRoutes: RoutesProps = {
  path: "/invoices/all",
  name: "Invoices",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "file-text",
  component: Invoices,
};

const viewInvoicesRoute: RoutesProps = {
  path: "/invoice/:invoiceId",
  name: "View Invoice",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "",
  component: ViewInvoice,
};

const resourcesAppRoutes: RoutesProps = {
  path: "/resources",
  name: "Resources",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "package",
  component: Resources,
};

const plansRoutes: RoutesProps = {
  path: "/plans",
  name: "Plans",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "briefcase",
  component: Plans,
};

const chatAppRoutes: RoutesProps = {
  path: "/apps/chat",
  name: "Chat",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "message-square",
  component: ChatApp,
};

const notificationRoute: RoutesProps = {
  path: "/notification",
  name: "Send notification",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "bell",
  component: Notifications,
};

const reportRoute: RoutesProps = {
  path: "/reports",
  name: "Reports",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "alert-octagon",
  component: Report,
};

const appRoutes = [calendarAppRoutes, chatAppRoutes];

// pages
const extrapagesRoutes: RoutesProps = {
  path: "/pages",
  name: "Pages",
  icon: "package",
  header: "Custom",
  children: [
    {
      path: "/pages/starter",
      name: "Starter",
      component: Starter,
      route: PrivateRoute,
    },
    {
      path: "/pages/profile",
      name: "Profile",
      component: Profile,
      route: PrivateRoute,
    },
    {
      path: "/pages/activity",
      name: "Activity",
      component: Activity,
      route: PrivateRoute,
    },
    {
      path: "/pages/invoice",
      name: "Invoice",
      component: Invoice,
      route: PrivateRoute,
    },
    {
      path: "/pages/pricing",
      name: "Pricing",
      component: Pricing,
      route: PrivateRoute,
    },
  ],
};

// ui
const uiRoutes: RoutesProps = {
  path: "/components",
  name: "Components",
  icon: "package",
  header: "UI Elements",
  children: [
    {
      path: "/components/ui-elements",
      name: "UIElements",
      component: UIElements,
      route: PrivateRoute,
    },
    {
      path: "/components/widgets",
      name: "Widgets",
      component: Widgets,
      route: PrivateRoute,
    },
    {
      path: "/forms",
      name: "Forms",
      children: [
        {
          path: "/forms/basic",
          name: "Basic Elements",
          component: BasicForms,
          route: PrivateRoute,
        },
        {
          path: "/forms/advanced",
          name: "Form Advanced",
          component: FormAdvanced,
          route: PrivateRoute,
        },
        {
          path: "/forms/validation",
          name: "Form Validation",
          component: FormValidation,
          route: PrivateRoute,
        },
        {
          path: "/forms/wizard",
          name: "Form Wizard",
          component: FormWizard,
          route: PrivateRoute,
        },
        {
          path: "/forms/upload",
          name: "File Upload",
          component: FileUpload,
          route: PrivateRoute,
        },
        {
          path: "/forms/editors",
          name: "Editors",
          component: Editors,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/components/charts",
      name: "Charts",
      component: Charts,
      route: PrivateRoute,
    },
    {
      path: "/tables",
      name: "Tables",
      children: [
        {
          path: "/tables/basic",
          name: "Basic",
          component: BasicTables,
          route: PrivateRoute,
        },
        {
          path: "/tables/advanced",
          name: "Advanced",
          component: AdvancedTables,
          route: PrivateRoute,
        },
      ],
    },
  ],
};

// auth
const authRoutes: RoutesProps[] = [
  {
    path: "/auth/login",
    name: "Login",
    component: Login,
    route: Route,
  },
  // {
  //   path: "/auth/register",
  //   name: "Register",
  //   component: Register,
  //   route: Route,
  // },
  {
    path: "/auth/confirm",
    name: "Confirm",
    component: Confirm,
    route: Route,
  },
  // {
  //   path: "/auth/forget-password",
  //   name: "Forget Password",
  //   component: ForgetPassword,
  //   route: Route,
  // },
  {
    path: "/auth/lock-screen",
    name: "Lock Screen",
    component: LockScreen,
    route: Route,
  },
  {
    path: "/auth/logout",
    name: "Logout",
    component: Logout,
    route: Route,
  },
];

// public routes
// const otherPublicRoutes: RoutesProps[] = [
//     {
//         path: '/landing',
//         name: 'landing',
//         component: Landing,
//         route: Route,
//     },
//     {
//         path: '/maintenance',
//         name: 'Maintenance',
//         component: Maintenance,
//         route: Route,
//     },
//     {
//         path: '/error-404',
//         name: 'Error - 404',
//         component: Error404,
//         route: Route,
//     },
//     {
//         path: '/error-500',
//         name: 'Error - 500',
//         component: Error500,
//         route: Route,
//     },
// ];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const authProtectedRoutes = [
  rootRoute,
  dashboardRoutes,
  ...appRoutes,
  extrapagesRoutes,
  uiRoutes,
  communityAppRoutes,
  resourcesAppRoutes,
  plansRoutes,
  invoicesRoutes,
  viewInvoicesRoute,
  userRoutes,
  teamRoutes,
  profileAppRoutes,
  notificationRoute,
  reportRoute,
];
const publicRoutes = [...authRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
};
