import { useEffect, useState } from "react";

import Routes from "./routes/Routes";

//import { configureFakeBackend } from './helpers';

// Themes
// For Default import Theme.scss
import "./assets/scss/Theme.scss";
import { Response } from "./constants/types";
import { getProfile } from "./helpers/api/profile";
import { ProfileContext } from "./Context";

// For Dark import Theme-Dark.scss
// import './assets/scss/Theme-Dark.scss';

// configure fake backend
//configureFakeBackend();

const App = () => {
  const [userName, setUserName] = useState<string>("");
  const [userImg, setUserImg] = useState<string>("");
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    getProfileData();
    setIsAdmin(JSON.parse(localStorage.getItem("isAdmin") || "false"));
  }, []);

  const setNewUsername = (newUsername: string, imageUrl: string) => {
    console.log({ newUsername });
    setUserImg(imageUrl);
    setUserName(newUsername);
  };

  const getProfileData = async () => {
    if (localStorage.getItem("AUTH_TOKEN")) {
      let res: Response = (await getProfile()) as Response;
      console.log(res);
      if (res.status === 200) {
        setUserImg(res?.data?.data.imageUrl);
        setUserName(`${res?.data?.data.firstName} ${res?.data?.data.lastName}`);
        console.log(userName);
      }
    }
  };

  return (
    <>
      <div id="recaptcha-container"></div>
      <ProfileContext.Provider
        value={{
          userName,
          userImg,
          setNewUsername,
          getProfileData,
          isAdmin,
          setIsAdmin,
        }}
      >
        <Routes />
      </ProfileContext.Provider>
    </>
  );
};

export default App;
